<template>
  <div class="centered">
    <h1 class="title-section" id="h03">Mes Projets</h1>
    <v-tabs center-active show-arrows dark v-model="tabs">
      <v-tab dark v-for="(donnee, j) in data" :key="j">{{ donnee.nom }}</v-tab>
    </v-tabs>
    <v-tabs-items dark v-model="tabs">
      <v-tab-item v-for="(donnee, j) in data" :key="j" class="tabItem">
        <div style="padding-top: 15px">
          {{ donnee.intitule }} <span class="italic">{{ donnee.lieu }}</span>
        </div>
        <div v-if="donnee.a != ''" class="vert">
          De {{ donnee.de }} à {{ donnee.a }}
        </div>
        <div v-else class="vert">Courant {{ donnee.de }}</div>
        <br />
        <v-img
          v-if="donnee.img != ''"
          contain
          :src="require('../assets/projets/' + donnee.img)"
          width="100%"
          max-height="800px"
        />
        <div v-html="donnee.descriptif"></div>
        <br />
        <div class="embed-container" v-if="donnee.youtube != ''">
          <iframe
            width="560"
            height="315"
            :src="'https://www.youtube.com/embed/' + donnee.youtube"
            :title="donnee.nom"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <v-chip
          outlined
          color="var(--green)"
          class="ma-2"
          v-for="(chip, k) in donnee.chips"
          :key="k"
          >{{ chip }}</v-chip
        >
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "Projets",

  data: () => ({
    tabs: null,
    data: [
      {
        nom: "Calculatrice",
        intitule: "Calculatrice avec historique de calcul sur iOS",
        lieu: "Angers, France",
        de: "juillet 2018",
        a: "août 2018",
        img: "calculette.webp",
        descriptif: `<br/>Calculatrice avec reprise d'historique de calcul, disponible sur iPhone et iPad
        <br/><a href='https://apps.apple.com/lv/app/calculette-pro/id1258190749' style='text-decoration:underline;color:var(--green)!important;'>Retrouvez la sur l'Apple Store</a>`,
        youtube: "",
        chips: ["Xcode", "Swift", "UIKit"],
      },
      {
        nom: "Catching Screen",
        intitule: "Cours de jeux vidéo",
        lieu: "Chicoutimi, Canada",
        de: "janvier 2020",
        a: "avril 2020",
        img: "",
        descriptif: `Ce projet est né pour le cours de création de jeux vidéo à l'UQAC. Le principe était de faire un serious game sous Unreal Engine.<br/><br/>
        Sans aucune connaissance sur ce moteur et avec deux camarades de classe, on est parti sur un jeu parlant de l'addiction aux jeux vidéo. Nous avons réalisé un système basé sur les Sims avec un personnage et des besoins à satisfaire. Lors du clique sur l'ordinateur portable on rentre dans un autre jeu, plutôt RPG basé sur Skyrim, ici on a des pouvoirs et il y a un château protégé par des dinosaures, dans celui-ci siège notre double maléfique que l'on doit vaincre.<br/><br/>
        La subtilité de notre projet est que la satisfaction des besoins influe sur les statistiques dans le RPG. Beaucoup de choses restent à faire pour le considérer fini, mais le manque de connaissance et de temps fera qu'il n'a jamais eu de réelle fin.`,
        youtube: "m8om6_D4HUY",
        chips: ["Unreal Engine", "Blueprint"],
      },
      {
        nom: "Détection de ligne",
        intitule: "Vision par ordinateur",
        lieu: "Laval, France",
        de: "juillet 2020",
        a: "août 2020",
        img: "",
        descriptif: `Le principe était à la base de faire une voiture autonome en simulateur, le projet ne devant pas dépasser 2 mois, j'ai réduit celui-ci à la détection des lignes de la route. Il se découpe en 4 grandes parties :
        <br>
        <a href="/#line-mk1">
          - <span class="vert">mk1</span> : Détection d'angle en B&W
        </a>
        <br>
        <a href="/#line-mk2">
        - <span class="vert">mk2</span> : Détection d'angle en HSL
        </a>
        <br>
        <a href="/#line-mk3">
        - <span class="vert">mk3</span> : Changement de perspective
        </a>
        <br>
        <a href="/#line-mk4">
        - <span class="vert">mk4</span> : Machine learning
        </a>
        <br>
        <br>
        <br>
        <div id="line-mk1" style="padding: 100px 0 0 0;">
          - <span class="vert">mk1</span> : Détection d'angle en B&W
          <br>
          J'ai commencé ce projet en suivant plusieurs tutoriels sur la détection d'angle. Le résultat (voir ci-dessous) était un bon début, mais clairement insuffisant pour s'en servir.
          <br>
          <br>
          <div class="embed-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLI76izQgk-KT5a_tX7kAGIp2N0AhHU24s" title="Détection d'angle en B&W" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div id="line-mk2" style="padding: 100px 0 0 0;">
          - <span class="vert">mk2</span> : Détection d'angle en HSL
          <br>
          Suite à la première partie, j'ai cherché à améliorer la détection d'angle, pour cela il fallait faire ressortir les lignes blanches par rapport au reste. En faisant mes essais avec les différents filtres, je me suis rendu compte qu'avec la vidéo en HSL, les lignes blanches apparaissaient bien rouges. Le résultat est nettement plus stable, mais n'est toujours pas viable dans les virages, car la détection d'angle ne fonctionne que pour des lignes droites.
          <br>
          <br>
          <div class="embed-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLI76izQgk-KQOyzYF6-8134k8IrFD_zFp" title="Détection d'angle en HSL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div id="line-mk3" style="padding: 100px 0 0 0;">
          - <span class="vert">mk3</span> : Changement de perspective
          <br>
          La problématique ayant évolué, j'ai choisi de changer radicalement de méthode. Afin d'accentué les courbes et de facilité la détection, je suis passé sur une perspective dite 'Bird eye' en mode vue d'oiseau le champ de vision est réduit, mais toute courbe même minime est amplifiée. La détection ne se fait plus par angle, mais par équation mathématique avec une courbe de tendance. Le gros problème de cette méthode est que la détection dépend du filtre de couleur, or celui-ci varie avec la météo. Malgré une tentative de réglage de seuil via une moyenne de couleur du ciel pour faire un ajustement dynamique, le résultat n'est toujours pas suffisant.
          <br>
          <br>
          <div class="embed-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLI76izQgk-KQmNghEBW9AQv3N591JV0QK" title="Changement de perspective" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div id="line-mk4" style="padding: 100px 0 0 0;">
          - <span class="vert">mk4</span> : Machine learning
          <br>
          L'ultime et inévitable méthode, le machine learning, après toutes mes tentatives pour le faire avec des algorithmes. Je m'en suis remis à l'intelligence artificielle. J'ai commencé par utiliser un modèle préentraîné, mais celui-ci ne fonctionnait que sur les routes américaines avec la ligne jaune. J'ai donc démarré la création de mon propre dataset, mais c'est une tâche vraiment très longue et je n'avais pas assez de données. J'ai donc rajouté au dataset américain le peu de données que j'avais préparé. Le résultat est pas mal, mais il manque des données dans différents cas, comme lors de passage d'ombre d'arbres sur la route.
          <br>
          <br>
          <div class="embed-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLI76izQgk-KS1kFAM7DIdn4BTbZ3Q_piH" title="Machine learning" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        `,
        youtube: "",
        chips: ["Python", "Keras", "PyTorch", "Vision par ordinateur"],
      },
      {
        nom: "Pocket Monster",
        intitule: "Cours de programmation orienté objet",
        lieu: "Laval, France",
        de: "février 2021",
        a: "",
        img: "",
        descriptif:
          "Évaluation du module programmation orienté objet, le principe était de faire une copie du jeu Pokémon. Le tout en C++ avec SDL pour l'interface graphique.",
        youtube: "HwZINKblRbw",
        chips: ["C++", "SDL"],
      },
      {
        nom: "Fast N Fat",
        intitule: "GameJam organisé par l'ESIEA",
        lieu: "Laval, France",
        de: "décembre 2021",
        a: "",
        img: "fastnfat.webp",
        descriptif: `<br/>Le but de cette GameJam est qu'à la fin du module 'introduction à Unity' nous devions réaliser un jeu complet de type shoot'em'up, aucune contrainte à part respecter le type. N'hesitez pas à aller faire une partie.
        <br/><br/><iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/1327564"><a href="https://rombond.itch.io/fastn-fat">Fast&#039;n Fat by Rombond, Eilimei</a></iframe>`,
        youtube: "",
        chips: ["Unity", "C#", "Blender"],
      },
      {
        nom: "Moteur de RayTracing",
        intitule: "Développement d'un moteur de RayTracing",
        lieu: "Laval, France",
        de: "février 2022",
        a: "",
        img: "raytracing.webp",
        descriptif:
          "<br/>L'évaluation de ce cours avait pour but de développer un moteur de RayTracing en C++, mais lors du développement il fallait plusieurs minutes pour obtenir une image. J'ai donc convertis le programme en CUDA pour parallelisé la projection des différents rayons, suite à cela j'ai implémenté le déplacement de la caméra afin d'avoir plusieurs point de vue, ainsi que de l'anti-aliasing. Et pour finir, j'ai optimisé le tout afin d'avoir 60fps constant.",
        youtube: "wZcfvFhIg8c",
        chips: ["RayTracing", "C++", "CUDA"],
      },
      {
        nom: "Splatoon Like",
        intitule: "Développement d'un jeu multijoueur",
        lieu: "Laval, France",
        de: "septembre 2022",
        a: "",
        img: "splatoon_like.webp",
        descriptif: `<br/>Petit TP de 3 jours sur le système de multijoueur de Unity (NetCode).<br/>À la Splatoon c'est une guerre de territoire, chaque joueur apparait dans une salle d'attente, ils peuvent confirmer leur participation et l'hébergeur peut lancer la partie pour 90 secondes. Les joueurs sont des boules et doivent venir "peindre" les blocs l'entourant, à la fin, ils sont mis en mode "spectateur" pour pouvoir voir toute la carte et potentiellement relancer une partie.`,
        youtube: "eoEYG11lY5g",
        chips: ["Unity", "C#", "Multijoueur", "NetCode"],
      },
      {
        nom: "Facto Bots",
        intitule: "Développement d'un jeu VR natif",
        lieu: "Laval, France",
        de: "octobre 2022",
        a: "",
        img: "",
        descriptif: `Pour ce module, nous devions développer un jeu qui tourne nativement sur un Quest 2, la règle du jeu et les contrôles devaient être compréhensible en moins d'une minute.`,
        youtube: "B9fFASpL25A",
        chips: ["Unity", "C#", "Oculus", "Quest 2"],
      },
      {
        nom: "Rapide & Furieux VR",
        intitule: "Développement d'UI",
        lieu: "Laval, France",
        de: "janvier 2023",
        a: "",
        img: "rapide_furieux_vr.webp",
        descriptif: `<br/>Simulateur de conduite en VR, lors de notre cours d'UI/UX, nous devions réaliser une application en VR en rapport avec la "Driving Simulation".`,
        youtube: "vStqbv7NE5I",
        chips: ["Unity", "C#"],
      },
      {
        nom: "Mon portfolio",
        intitule: "Développement web",
        lieu: "Laval, France",
        de: "janvier 2023",
        a: "",
        img: "portfolio.webp",
        descriptif:
          "<br>Réalisation d'un site web compatible mobile afin de montrer qui je suis",
        youtube: "",
        chips: ["HTML", "CSS", "JavaScript", "NodeJS", "VueJS", "Vuetify"],
      },
    ],
  }),
};
</script>

<style>
h1#h03::before {
  content: "03. ";
  color: var(--green);
}

.tabItem {
  padding: 0 24px 16px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>