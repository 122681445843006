<template>
  <v-app>
    <v-app-bar app color="var(--bg)" height="100px" elevate-on-scroll>
      <a class="d-flex align-center" href="/">
        <v-img
          alt="Brebond Logo"
          class="shrink"
          contain
          :src="require('./assets/brebond_transparent.png')"
          transition="scroll-y-transition"
          width="100"
        />
      </a>

      <v-spacer></v-spacer>

      <div class="d-none d-md-flex">
        <ol class="menu">
          <li>
            <a href="/#about"> Mon Profil </a>
          </li>
          <li>
            <a href="/#experience"> Mon Parcours </a>
          </li>
          <li>
            <a href="/#projects"> Mes Projets </a>
          </li>
          <li>
            <a href="/#contact"> Mes Coordonnées </a>
          </li>
        </ol>

        <v-btn class="btn-resume" href="./resume.pdf" target="_blank" outlined>
          C.V.
        </v-btn>
      </div>
      <div class="d-block d-md-none">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          color="var(--txt)"
        ></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      right
      temporary
      width="200px"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item href="/#about">
            <v-list-item-title
              ><span class="vert">01. </span>Mon Profil</v-list-item-title
            >
          </v-list-item>

          <v-list-item href="/#experience">
            <v-list-item-title
              ><span class="vert">02. </span>Mon Parcours</v-list-item-title
            >
          </v-list-item>

          <v-list-item href="/#projects">
            <v-list-item-title
              ><span class="vert">03. </span>Mes Projets</v-list-item-title
            >
          </v-list-item>

          <v-list-item href="/#contact">
            <v-list-item-title
              ><span class="vert">04. </span>Mes Coordonnées</v-list-item-title
            >
          </v-list-item>

          <v-list-item href="./resume.pdf" target="_blank">
            <v-list-item-title
              ><span class="vert">05. </span>Mon C.V.</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <section id="main">
        <h1 class="main">Bienvenue sur mon portfolio, je suis</h1>
        <h2 class="main">Romain Bondevine.</h2>
        <h3 class="main">Développeur passionné.</h3>
        <p class="main">
          Je suis un ingénieur en informatique passionné par la création de logiciels et d'applications variés. 
          Bien que j'aie commencé dans l'univers de la 3D et de la réalité virtuelle, je me suis depuis tourné vers la programmation logicielle et embarquée. 
          J'aime explorer de nouveaux défis technologiques et suis toujours curieux de découvrir d'autres domaines.
        </p>
      </section>
      <v-divider></v-divider>
      <APropos class="components" id="about" />
      <v-divider></v-divider>
      <Parcours class="components" id="experience" />
      <v-divider></v-divider>
      <Projets class="components" id="projects" />
      <v-divider></v-divider>
      <Contact class="components" id="contact" />
    </v-main>
  </v-app>
</template>

<script>
import APropos from "./components/1APropos";
import Parcours from "./components/2Parcours";
import Projets from "./components/3Projets";
import Contact from "./components/4Contact";

export default {
  name: "App",

  components: {
    APropos,
    Parcours,
    Projets,
    Contact,
  },

  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style>
section {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
}

.full-width {
  width: 100%;
  height: 75%;
}

.title-section {
  margin: 25px 0px 40px;
  width: 100%;
  font-size: clamp(1.2em, 6vw, 2em);
}

section#main {
  padding: 0px 25px;
}

.components {
  padding: 100px 25px;
  min-height: calc(100vh);
}

.centered {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

h1.main {
  color: var(--green);
  font-size: clamp(14px, 5vw, 25px);
}

h2.main {
  font-size: clamp(30px, 6vw, 80px);
  font-weight: bold;
}

h3.main {
  font-size: clamp(20px, 5vw, 80px);
  color: gray;
}

p.main {
  max-width: 700px;
  margin-top: 30px;
}

.theme--light.v-divider {
  max-width: 65%;
  border-color: var(--divider);
  margin: auto;
}

.v-main {
  background-color: var(--bg);
  padding: 0px 150px;
}

.theme--light.v-btn {
  color: var(--txt);
  font-family: var(--font);
}

.theme--light.v-application {
  color: var(--txt);
  font-family: var(--font);
}

.theme--light.v-sheet {
  color: var(--txt);
  font-family: var(--font);
}

.theme--dark.v-list-item--active:before {
  opacity: 0;
}

.btn-resume {
  margin-left: 15px;
}

.menu {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px !important;
  margin: 0px;
  list-style: none;
  overflow: hidden;
  white-space: nowrap;
}

.menu li {
  margin: 0px 5px;
  position: relative;
  counter-increment: item 1;
}

.menu li a::before {
  content: "0" counter(item) ".";
  color: var(--green);
}

.menu li a {
  color: lightgray;
  text-decoration: none;
  padding: 10px;
}

.vert {
  color: var(--green);
}

:root {
  --green: #00ff00;
  --bg: #171717;
  --divider: lightgray;
  --txt: lightgray;
  --font: "SF Mono", sans-serif;
}

@media (min-width: 960px) {
  section#main {
    padding: 0px 150px;
  }

  .components {
    padding: 100px 150px;
  }
}
</style>