<template>
  <div class="centered">
    <h1 class="title-section" id="h04">Mes Coordonnées</h1>
    <div>
      <v-icon class="ma-2" dark>mdi-email</v-icon>
      <a href="mailto:rombond@brebond.com">rombond@brebond.com</a>
    </div>
    <div>
      <v-icon class="ma-2" dark>mdi-phone</v-icon>
      <a href="tel:+3368931964">+1 (579) 679-9876</a>
    </div>
    <div>
      <v-icon class="ma-2" dark>mdi-linkedin</v-icon>
      <a href="https://www.linkedin.com/in/romain-bondevine/"
        >Romain Bondevine</a
      >
    </div>
    <div>
      <img
      class="ma-2 imgicon invert"
        height="24"
        width="24"
        src="https://cdn.jsdelivr.net/npm/simple-icons/icons/discord.svg"
      />
      <a href="https://discordapp.com/users/246673600860520448">rombond</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",

  data: () => ({
    //
  }),
};
</script>

<style>
h1#h04::before {
  content: "04. ";
  color: var(--green);
}

a {
  text-decoration: none;
  color: var(--txt) !important;
}

.imgicon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.invert {
  filter: invert(100%);
}
</style>