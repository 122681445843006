<template>
  <div class="centered">
    <h1 class="title-section" id="h01">Mon Profil</h1>
    <div class="separate">
      <div class="texte">
        Salut ! Je m'appelle Romain et je suis un passionné d'informatique et d'innovation. 
        Mon aventure a commencé en créant des "mods" pour Minecraft, et aujourd'hui, 
        après 4 années d'expérience en développement, je me suis spécialisé dans la programmation logicielle et embarquée.
        <br />
        <br />
        J'aime relever des défis et mettre mes idées en pratique, que ce soit dans mon travail ou mes projets personnels. 
        Chez moi, j'ai monté un serveur qui me permet d'héberger mes sites web, de gérer ma maison connectée, de stocker mes données et même de développer mes projets. 
        J'aime aussi explorer la création d'objets en modélisant et imprimant des pièces en 3D.
        <br />
        <br />
        En ce moment, je travaille sur des projets ambitieux, comme concevoir une serrure connectée et prototyper un assistant vocal pour ma maison, un peu comme un "Jarvis". 
        Mon objectif est de créer des solutions pratiques et innovantes qui rendent le quotidien plus simple et plus agréable.
        <br />
        <br />
        Installé à Laval, au Canada, je suis toujours à la recherche de nouvelles opportunités et de collaborations. Si mon profil vous intrigue, n'hésitez pas à me contacter !"
      </div>
      <div class="photo">
        <v-img
          cover
          :src="require('../assets/romain.webp')"
          transition="scale-transition"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "APropos",

  data: () => ({
    //
  }),
};
</script>

<style>
h1#h01::before {
  content: "01. ";
  color: var(--green);
}

.separate {
  display: block;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}

.photo {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media (min-width: 960px) {
  .separate {
    display: grid;
  }

  .photo {
    margin: 0;
    width: 300px;
  }
}
</style>